.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.selectorContainer {
    width: 90vw;
}

.title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
}

.cardContainer {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1001;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup h2 {
    margin-bottom: 10px;
}

.popup p {
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    gap: 10px; /* Espace entre les boutons */
}

.popup button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.popup button:hover {
    background-color: #0056b3;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

