.container {
    display: flex;
    justify-content: center;
}

.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4.5vw;
    align-items: center;
    padding: 0.75rem;
    background-color: #f9f9f9;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 0.575rem auto;
    height: 8vh;
    width: 90vw;
}

.colorBox {
    color: white;
    width: 10vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    font-size: clamp(8px, 1.4vw, 16px);
}

.attributeName {
    padding: 1vw;
}
