.container {
    display: flex;
    justify-content: center;
}

.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4.5vw;
    align-items: center;
    padding: 0.75rem;
    background-color: #f9f9f9;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 0 auto;
    height: 5vh;
    width: 90vw;
}

.colorBox {
    color: black;
    width: 10vw;
    height: 2.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    position: relative;
    font-size: clamp(8px, 1.4vw, 16px);
}

.attributeName {
    padding: 1vw;
}

.infoIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.tippy-box[data-theme~="custom"] {
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    padding: 4px 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
}

.tippy-box[data-theme~="custom"] .tippy-content {
    font-size: 0.8rem;
    text-align: center;
}
